<template>
    <div class="join">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="type">
            <div class="typename">
                <div class="title">{{$store.state.cn_en_list[87]}}</div>
                <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                    <el-carousel-item v-for="(item , index) in 1" :key="index">
                        <img src="./../../assets/img/meeting1.png" alt="" class="typeImg">
                    </el-carousel-item>
                </el-carousel>
                
                <div class="textBox">
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>{{$store.state.cn_en_list[88]}}</div>
                    </div>
                    <div class="text center">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>{{$store.state.cn_en_list[89]}}</div>
                    </div>
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>{{$store.state.cn_en_list[90]}}</div>
                    </div>
                </div>
            </div>

            <div class="typename">
                <div class="title">{{$store.state.cn_en_list[91]}}</div>
                <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                    <el-carousel-item v-for="(item , index) in 1" :key="index">
                        <img src="./../../assets/img/meeting2.png" alt="" class="typeImg">
                    </el-carousel-item>
                </el-carousel>
                
                <div class="textBox">
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>{{$store.state.cn_en_list[92]}}</div>
                    </div>
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>{{$store.state.cn_en_list[93]}}</div>
                    </div>
                    <div class="text" v-if="$store.state.language.type == 1">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>社交晚会将进一步完善参会代表们的国际大会体验，拓展了交流渠道和社交能力，结识更多优秀的同龄人。</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="part">
            <div class="title">{{$store.state.cn_en_list[94]}}</div>
            <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                <el-carousel-item v-for="(item , index) in 1" :key="index">
                    <img src="./../../assets/img/meeting3.png" alt="" class="typeImg">
                    <div class="button" @click="goAmbassador">{{$store.state.cn_en_list[95]}}</div>
                </el-carousel-item>
            </el-carousel>
            <div class="listBox">
                <div class="list">
                    <div class="tip">
                        <div class="listtitle">{{$store.state.cn_en_list[96]}}</div>
                        <div class="circle circleleft"></div>
                        <div class="circle circleright"></div>
                        <div class="lineBox lineBoxleft">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                        <div class="lineBox lineBoxright">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                    </div>

                    <div class="contentBox">
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">{{$store.state.cn_en_list[97]}}</div>
                        </div>
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">{{$store.state.cn_en_list[98]}}</div>
                        </div>
                    </div>
                </div>
                <div class="list">
                    <div class="tip">
                        <div class="listtitle">{{$store.state.cn_en_list[99]}}</div>
                        <div class="circle circleleft"></div>
                        <div class="circle circleright"></div>
                        <div class="lineBox lineBoxleft">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                        <div class="lineBox lineBoxright">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                    </div>

                    <div class="contentBox">
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">{{$store.state.cn_en_list[100]}}</div>
                        </div>
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">{{$store.state.cn_en_list[101]}}</div>
                        </div>
                    </div>
                </div>
                <div class="list">
                    <div class="tip">
                        <div class="listtitle">{{$store.state.cn_en_list[102]}}</div>
                        <div class="circle circleleft"></div>
                        <div class="circle circleright"></div>
                        <div class="lineBox lineBoxleft">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                        <div class="lineBox lineBoxright">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                    </div>

                    <div class="contentBox">
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">{{$store.state.cn_en_list[103]}}</div>
                        </div>
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">{{$store.state.cn_en_list[104]}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="schedule">
            <div class="title">{{$store.state.cn_en_list[153]}}</div>
            <div class="content">
                <div class="left">
                    <div class="timeBox">
                        <div class="day">Day 1</div>
                        <div class="time">
                            <div class="timelist" v-for="(item , index) in step[0] " :key="index">
                                <div class="lineCut" v-if="index != 0"></div>
                                <div class="listBox">
                                    <div class="clock">
                                        <img src="./../../assets//img//clock.png" alt="">
                                        <span>{{ item.time }}</span>
                                    </div>
                                    <div class="stepName">{{ $store.state.language.type == 1 ? item.name : item.name_en }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="timeBox second">
                        <div class="day">Day 2</div>
                        <div class="time">
                            <div class="timelist" v-for="(item , index) in step[1] " :key="index">
                                <div class="lineCut" v-if="index != 0"></div>
                                <div class="listBox">
                                    <div class="clock">
                                        <img src="./../../assets//img//clock.png" alt="">
                                        <span>{{ item.time }}</span>
                                    </div>
                                    <div class="stepName">{{ $store.state.language.type == 1 ? item.name : item.name_en }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="right">
                    <div class="timeBox">
                        <div class="day">Day 3</div>
                        <div class="time">
                            <div class="timelist" v-for="(item , index) in step[2] " :key="index">
                                <div class="lineCut" v-if="index != 0"></div>
                                <div class="listBox">
                                    <div class="clock">
                                        <img src="./../../assets//img//clock.png" alt="">
                                        <span>{{ item.time }}</span>
                                    </div>
                                    <div class="stepName">{{ $store.state.language.type == 1 ? item.name : item.name_en }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="timeBox second">
                        <div class="day">Day 4</div>
                        <div class="time">
                            <div class="timelist" v-for="(item , index) in step[3] " :key="index">
                                <div class="lineCut" v-if="index != 0"></div>
                                <div class="listBox">
                                    <div class="clock">
                                        <img src="./../../assets//img//clock.png" alt="">
                                        <span>{{ item.time }}</span>
                                    </div>
                                    <div class="stepName">{{ $store.state.language.type == 1 ? item.name : item.name_en }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="desc">{{$store.state.cn_en_list[154]}}</div>
        </div>

        <div class="Awards">
            <div class="title">{{$store.state.cn_en_list[105]}}</div>
            <div class="awardContent">
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award1.png" alt="">
                        <span  :class="$store.state.language.type == 1 ? 'cn_span' : ''">{{$store.state.cn_en_list[106]}}</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[107]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[108]}}</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[111]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[112]}}</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[109]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[110]}}</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[113]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[114]}}</div>
                        </div>
                    </div>
                </div>
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award2.png" alt="">
                        <span :class="$store.state.language.type == 1 ? 'cn_span' : ''">{{$store.state.cn_en_list[115]}}</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[116]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[117]}}</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[120]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[121]}}</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[118]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[119]}}</div>
                        </div>
                    </div>
                </div>
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award3.png" alt="">
                        <span :class="$store.state.language.type == 1 ? 'cn_span' : ''">{{$store.state.cn_en_list[122]}}</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[123]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[124]}}</div>
                        </div>
                    </div>
                </div>
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award4.png" alt="">
                        <span :class="$store.state.language.type == 1 ? 'cn_span' : ''">{{$store.state.cn_en_list[125]}}</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[126]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[127]}}</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[128]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[129]}}</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">{{$store.state.cn_en_list[130]}}</div>
                            </div>
                            <div class="desc">{{$store.state.cn_en_list[131]}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info">
                <div>{{$store.state.cn_en_list[132]}}</div>
                <div>{{$store.state.cn_en_list[133]}}</div>
                <div>{{$store.state.cn_en_list[134]}}</div>
                <div>{{$store.state.cn_en_list[135]}}</div>
            </div>
        </div>

        <div class="content">
            <div class="title">{{$store.state.cn_en_list[136]}}</div>
            <div class="root">
                <div class="rootBox" @click="nextPage(1)">
                <img src="./../../assets/img/join1.png" alt="" >
                <div class="title">{{$store.state.cn_en_list[6]}}</div>
                <div class="model"></div>
            </div>
            <div class="rootBox" @click="nextPage(2)">
                <img src="./../../assets/img/join2.png" alt="" >
                <div class="title">{{$store.state.cn_en_list[7]}}</div>
                <div class="model"></div>
            </div>
            <div class="rootBox" @click="nextPage(3)">
                <img src="./../../assets/img/join3.png" alt="" >
                <div class="title">{{$store.state.cn_en_list[8]}}</div>
                <div class="model"></div>
            </div>
            <div class="rootBox" @click="nextPage(4)">
                <img src="./../../assets/img/join4.png" alt="" >
                <div class="title">{{$store.state.cn_en_list[9]}}</div>
                <div class="model"></div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import {banner} from './../../api/api'
import {config} from './../../utils/config'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {},
            step: [
            [{
                    time: '13:00-14:30',
                    name: '代表签到',
                    name_en : 'Registration'
                },
                {
                    time: '15:30-16:00',
                    name: '委员会报到',
                    name_en : 'Committee Registration'
                },
                {
                    time: '16:00-17:30',
                    name: '大会开幕式',
                    name_en : 'Opening Ceremony'
                },
                {
                    time: '17:30-19:00',
                    name: '晚餐',
                    name_en : 'Dinner'
                },
                {
                    time: '19:00-20:00',
                    name: '破冰环节',
                    name_en : 'Icebreaking Session'
                },
                {
                    time: '20:00-21:30',
                    name: '分组会议1',
                    name_en : 'Committee Session 1'
				},
				{
                    time: '22:30',
                    name: '宵禁',
                    name_en : 'Curfew'
                },
            ],
            [{
                    time: '9:00-12:00',
                    name: '分组会议2',
                    name_en : 'Committee Session 2'
                },
                {
                    time: '12:00-13:00',
                    name: '午餐',
                    name_en : 'Lunch'
                },
                {
                    time: '14:30-17:30',
                    name: '分组会议3',
                    name_en : 'Committee Session 3'
                },
                {
                    time: '17:30-19:00',
                    name: '晚餐',
                    name_en : 'Dinner'
                },
                {
                    time: '19:00-21:30',
                    name: '分组会议4',
                    name_en : 'Committee Session 4'
                },
                {
                    time: '22:30',
                    name: '宵禁',
                    name_en : 'Curfew'
                },
            ],
            [{
                    time: '9:00-12:00',
                    name: '分组会议5',
                    name_en : 'Committee Session 5'
                },
                {
                    time: '12:00-13:00',
                    name: '午餐',
                    name_en : 'Lunch'
                },
                {
                    time: '14:30-17:30',
                    name: '分组会议6',
                    name_en : 'Committee Session 6'
                },
                {
                    time: '17:30-18:30',
                    name: '晚餐',
                    name_en : 'Dinner'
                },
                {
                    time: '18:30-19:30',
                    name: '藤校申请讲座',
                    name_en : 'Seminar on Ivy League'
                },
                {
                    time: '20:00-22:30',
                    name: '社交晚会',
                    name_en : 'Gala Night'
                },
                {
                    time: '23:00',
                    name: '宵禁',
                    name_en : 'Curfew'
                },
            ],
            [{
                    time: '08:30-10:00',
                    name: '分组会议7',
                    name_en : 'Committee Session 7'
                },
                {
                    time: '10:30-12:00',
                    name: '闭幕式',
                    name_en : 'Closing Ceremony'
                },
                {
                    time: '12:00',
                    name: '代表返程',
                    name_en : 'Check-out'
                },
            ]
        ],
        }
    },
    mounted(){
        if(this.$store.state.language.type == 1){
            this.configData = config.join.chinese
        }else{
            this.configData = config.join.english
        }
        banner({'seat' : 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        nextPage(type){
			if(type == 1){
				this.$router.push('/weiyuan')
			}else if(type == 2){
				this.$router.push('/preparation')
			}else if(type == 3){
				this.$router.push('/food')
			}else if(type == 4){
				// this.$router.push('/city')
                this.$message({ message: this.$store.state.cn_en_list[156], type: 'success' })
			}
		},
        //校园大使报名
        goAmbassador(){
            this.$router.push('/Ambassador')
        }
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.join.chinese
			}else{
				this.configData = config.join.english
			}
		}
	}
}
</script>


<style lang="less" scoped>
.join{
    background: #1C1C1C;
    padding-bottom: 150px;
    .Awards{
        width: 1200px;
        margin: 80px auto 0;
        .info{
            font-size: 18px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #AAAAAA;
            line-height: 27px;
            margin-top: 32px;
        }
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 73px;
            text-align: center;
        }
        .awardContent{
            .award{
                width: 1200px;
                height: 437px;
                background: linear-gradient(180deg, rgba(54,54,54,0.65) 0%, rgba(53,53,53,0) 40%, rgba(52,52,52,0) 68%, rgba(51,51,51,0.65) 100%);
                border-radius: 8px;
                border: 2px solid #fff;
                margin-top: 48px;
                display: flex;
                align-items: center;
                .name{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: 90px;
                    margin-right: 110px;
                    width: 148px;
                    img{
                        width: 107px;
                        height: 108px;
                    }
                    span{
                        font-size: 40px;
                        font-family: YouSheBiaoTiHei;
                        color: #FFFFFF;
                        line-height: 52px;
                        margin-top: 40px;
                        white-space: break-spaces;
                        text-align: center;
                    }
                    .cn_span{
                        white-space: nowrap;
                    }
                }
                .tips{
                    display: flex;
                    flex-wrap: wrap;
                    height: 346px;
                    .tipBox{
                        .circleBox{
                            display: flex;
                            align-items: center;
                            .circle{
                                width: 12px;
                                height: 12px;
                                background: #FFFFFF;
                                border-radius: 50%;
                            }
                            .tipName{
                                font-size: 24px;
                                font-family: AlibabaPuHuiTi_2_55_Regular;
                                color: #3DA6D5;
                                line-height: 33px;
                                margin-left: 20px;
                            }
                        }
                        .desc{
                            font-size: 20px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #FFFFFF;
                            line-height: 30px;
                            margin-top: 16px;
                            width: 291px;
                            margin-left: 32px;
                        }
                    }
                    .tipBox:nth-of-type(2n){
                        margin-left: 96px;
                    }
                }
            }
            .award:nth-of-type(1){
                margin-top: 80px;
            }
            .award:nth-of-type(3){
                .tips{
                    align-items: center;
                }
            }
        }
    }
    .schedule{
        width: 1200px;
        margin: 120px auto 0;
        overflow: hidden;
        .desc{
            font-size: 14px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #AAAAAA;
            line-height: 27px;
            margin-top: 36px;
            text-align: center;
        }
        .content{
            margin-top: 48px;
            display: flex;
            justify-content: center;
            .line{
                width: 1px;
                opacity: 0.4;
                border: 1px solid #FFFFFF;
                float: left;
                margin-left: 80px;
            }
            .left{
                display: flex;
                flex-direction: column;
            }
            .right{
                margin-left: 80px;
                display: flex;
                flex-direction: column;
            }
            .timeBox{
                .time{
                    margin-top: 37px;
                    .timelist{
                        .lineCut{
                            width: 1px;
                            height: 34px;
                            opacity: 0.6;
                            border: 1px solid #FFFFFF;
                            margin: 9px 0 5px 11px;
                        }
                        .listBox{
                            display: flex;
                            align-items: center;
                            .stepName{
                                font-size: 20px;
                                font-family: 'AlibabaPuHuiTi_2_55_Regular';
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 20px;
                            }
                        }
                        .clock{
                            font-size: 20px;
                            font-family: YouSheBiaoTiHei;
                            color: #FFFFFF;
                            line-height: 26px;
                            display: flex;
                            align-items: center;
                            width: 227px;
                            img{
                                width: 24px;
                                height: 24px;
                                margin-right: 40px;
                            }
                        }
                    }
                }
                .day{
                    font-size: 28px;
                    font-family: YouSheBiaoTiHei;
                    color: #3DA6D5;
                    line-height: 36px;
                }
            }
            .second{
                margin-top: 50px;
            }
        }
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 73px;
            text-align: center;
        }
    }
    .part{
        width: 1200px;
        margin: 120px auto 0;
        .listBox{
            margin-top: 78px;
            display: flex;
            justify-content: space-between;
            .list{
                width: 384px;
                height: 266px;
                background: #333333;
                border-radius: 8px;
                position: relative;
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 40px;
                    float: left;
                    width: 100%;
                    .text{
                        display: flex;
                        .span{
                            font-size: 20px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #D1D1D1;
                            line-height: 30px;
                            width: 315px;
                        }
                        .circle{
                            width: 9px;
                            height: 9px;
                            background: #FFFFFF;
                            border-radius: 50%;
                            margin-right: 12px;
                            margin-top: 11px;
                        }
                    }
                    .text:nth-of-type(2){
                        margin-top: 18px;
                    }
                }
                .tip{
                    width: 100%;
                    height: 42px;
                    background: #FFFFFF;
                    border-radius: 21px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -54px;
                    .listtitle{
                        font-size: 24px;
                        font-family: YouSheBiaoTiHei;
                        color: #1C1C1C;
                        line-height: 42px;
                        text-align: center;
                    }
                    .circle{
                        width: 14px;
                        height: 14px;
                        background: #1C1C1C;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .circleleft{
                        left: 16px;
                    }
                    .circleright{
                        right: 16px;
                    }
                    .lineBox{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: absolute;
                        top: 34px;
                        .line{
                            width: 2px;
                            height: 26px;
                            background: #fff;
                        }
                        .fffcircle{
                            width: 10px;
                            height: 10px;
                            background: #FFFFFF;
                            border-radius: 50%;
                            margin-top: -2px;
                        }
                    }
                    .lineBoxleft{
                        left: 27px;
                    }
                    .lineBoxright{
                        right: 27px;
                    }
                }
            }
            .list:nth-of-type(2){
                .tip{
                    width: 256px;
                    .lineBoxleft{
                        left: 19px;
                    }
                    .lineBoxright{
                        right: 19px;
                    }
                }
            }
            .list:nth-of-type(3){
                .contentBox{
                    .text{
                        margin-top: 24px;
                    }
                    .text:nth-of-type(1){
                        margin-top: 0;
                    }
                }
                .tip{
                    width: 256px;
                    .lineBoxleft{
                        left: 19px;
                    }
                    .lineBoxright{
                        right: 19px;
                    }
                }
            }
        }
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 73px;
            text-align: center;
        }
        .carousel{
            margin-top: 45px;
            height: 480px;
            /deep/ .el-carousel__container{
                height: 450px;
            }
            .typeImg{
                width: 100%;
                height: 100%;
            }
            .button{
                width: 220px;
                height: 78px;
                background: rgba(0,0,0,0.2);
                border-radius: 8px;
                border: 1px solid #FFFFFF;
                font-size: 42px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 76px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50% , -50%);
                text-align: center;
                cursor: pointer;
            }
        }
    }
    .type{
        width: 1200px;
        margin: 0 auto;
        .typename{
            margin: 120px auto 0;
            .title{
                font-size: 34px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 73px;
                text-align: center;
            }
            .carousel{
                margin-top: 45px;
                height: 630px;
                /deep/ .el-carousel__container{
                    height: 600px;
                }
                .typeImg{
                    width: 100%;
                    height: 100%;
                }
            }
            
            .textBox{
                margin-top: 32px;
                .text{
                    display: flex;
                    font-size: 20px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #D1D1D1;
                    line-height: 20px;
                    margin-top: 24px;
                    .circleBox{
                        margin-left: 12px;
                        margin-right: 8px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        .circle{
                            width: 9px;
                            height: 9px;
                            background: #FFFFFF;
                            border-radius: 50%;
                        }
                    }
                }
                .center{
                    line-height: 40px;
                    .circleBox{
                        height: 40px;
                    }
                }
            }
        }
        .typename:nth-of-type(1){
            margin-top: 64px;
        }
    }
    .content{
        width: 1200px;
        margin: 0 auto;
        margin: 120px auto 0;
        .title{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 73px;
            text-align: center;
        }
        .root{
            overflow: hidden;
            margin-top: 48px;
            border-radius: 8px;
            .rootBox{
                width: 600px;
                height: 421px;
                position: relative;
                float: left;
                cursor: pointer;
                overflow: hidden;
                .model{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .title{
                    padding: 0 32px;
                    height: 78px;
                    background: rgba(56,56,56,0.2);
                    border: 1px solid #FFFFFF;
                    font-size: 42px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 76px;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                    border-radius: 8px;
                    z-index: 22;
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .rootBox:hover{
                .title{
                    background: #3DA6D5;
                    border-color: #3DA6D5;
                }
                img{
                    transform: scale(1.1);
                    transition: 0.2s;
                }
                .model{
                    background: rgba(0, 0, 0, 0.6);
                }
            }
        }
        img{
            display: block;
        }
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>