<template>
    <div class="food">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>
        <div class="typeBox typeBox1">
            <div class="type">
                <span>北京环球度假区</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn1" @touchend="touchendFn1">
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 2"   :key="index"></div>
                    </div>
                    <el-carousel   @change="change1" ref="carousel1"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/cityone' + item + '.png' " alt="" class="img">
                                <div class="button">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    北京环球度假区包含七大主题景区，分别有“哈利波特的魔法世界”、“侏罗纪世界”、“变形金刚基地”、“小黄人乐园”、“功夫熊猫盖世之地”、“好莱坞”、“未来水世界”。打卡完喜欢的主题景区后，再到“能量晶体补充站”、“落霞餐厅”、“哈蒙德餐厅”、“漂流者小馆”享受各市中西美食。对了，千万别错过环球全明星大巡游、以及去纪念品商店，把快乐打包带回家。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>故宫博物院</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn2" @touchend="touchendFn2">
                    <div class="dots">
                        <div :class="Current2 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 2"   :key="index"></div>
                    </div>
                    <el-carousel   @change="change2" ref="carousel2"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/citytwo' + item + '.png' " alt="" class="img">
                                <div class="button">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    故宫是中国最著名的古建筑之一，也是中国明清两代的皇宫。故宫建于明朝，是中国最大的古代宫殿建筑群，也是世界上最大的宫殿建筑群之一。游客可以在这里欣赏到中国古代宫廷建筑的精美和华丽，感受到中国古代文化的博大精深。故宫博物院被评为国家5A级旅游景区和世界遗产。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>天坛</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn3" @touchend="touchendFn3">
                    <div class="dots">
                        <div :class="Current3 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 2"   :key="index"></div>
                    </div>
                    <el-carousel   @change="change3" ref="carousel3"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/citythree' + item + '.png' " alt="" class="img">
                                <div class="button">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    北京天坛，是明、清两朝皇帝祭天、求雨和祈祷丰年的专用场所，也是中国古建筑中的璀璨明珠。雕梁画栋的古建筑，古树名木苍翠葱郁，营造出庄严肃穆的氛围。祈年殿是天坛的著名建筑，鎏金宝顶，蓝瓦红柱，蓝天白云下的祈年殿拍照十分出片！游客可以在这里领略古代宗教文化的魅力，感受中国传统文化的博大精深。天坛公园被评为国家5A级旅游景区和世界遗产。
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>颐和园</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn4" @touchend="touchendFn4">
                    <div class="dots">
                        <div :class="Current4 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 2"   :key="index"></div>
                    </div>
                    <el-carousel   @change="change4" ref="carousel4"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/cityfourth' + item + '.png' " alt="" class="img">
                                <div class="button">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    颐和园，是中国现存最大的皇家园林之一，世界上独一无二的园林景观。走进这座历史悠久、文化底蕴深厚的皇家园林，有古朴秀雅的亭台楼阁建筑，也有湖广山色的自然美景。园林中的每一个角落都充满了诗情画意，游客可以在这里体验皇家园林的美景和历史文化的魅力。颐和园被评为国家5A级旅游景区和世界遗产。
                </div>
            </div>
        </div>
        <div class="typeBox">
            <div class="type">
                <span>八达岭长城</span>
            </div>
            <div class="info">
                <div class="left"  @touchstart="touchstartFn5" @touchend="touchendFn5">
                    <div class="dots">
                        <div :class="Current5 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 2"   :key="index"></div>
                    </div>
                    <el-carousel   @change="change5" ref="carousel5"  class="carousel" arrow="never" :interval="10000"   indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 2" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/cityfive' + item + '.png' " alt="" class="img">
                                <div class="button">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    长城是中国的标志性建筑，也是中国古代的军事防御工程。长城始建于春秋战国时期，历经数百年的修建和扩建，成为了中国古代的一道壮丽的风景线。游客可以在长城上欣赏到壮观的山水风光，感受到中国古代文化的博大精深。八达岭长城是明长城中保存最好的一段，地势险要，城关坚固。八达岭-慕田峪长城旅游区被评为国家5A级旅游景区。
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from './../../../utils/config'
import {banner} from './../../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {},
            Current1 : 0,
            startMove1 : 0,
            Current2 : 0,
            startMove2 : 0,
            Current3 : 0,
            startMove3 : 0,
            Current4 : 0,
            startMove4 : 0,
            Current5 : 0,
            startMove5 : 0,
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
        }else{
            this.configData = config.weiyuan.english
        }
    },
    mounted(){
        
        banner({'seat' : 10,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	},
    methods : {
        change1(Current1){
            this.Current1 = Current1
        },
        change2(Current2){
            this.Current2 = Current2
        },
        change3(Current3){
            this.Current3 = Current3
        },
        change4(Current4){
            this.Current4 = Current4
        },
        change5(Current5){
            this.Current5 = Current5
        },
        touchstartFn1(e){
            this.startMove1 = e.changedTouches[0].clientX
        },
        touchendFn1(e){
            if(e.changedTouches[0].clientX - this.startMove1 > 50 ){
                this.changePage1(-1)
            }else if(e.changedTouches[0].clientX - this.startMove1 < -50){
                this.changePage1(1)
            }
        },
        changePage1(page){
            if(page > 0){
                this.$refs.carousel1.next()
            }else{
                this.$refs.carousel1.prev()
            }
        },

        touchstartFn2(e){
            this.startMove2 = e.changedTouches[0].clientX
        },
        touchendFn2(e){
            if(e.changedTouches[0].clientX - this.startMove2 > 50 ){
                this.changePage2(-2)
            }else if(e.changedTouches[0].clientX - this.startMove2 < -50){
                this.changePage2(2)
            }
        },
        changePage2(page){
            if(page > 0){
                this.$refs.carousel2.next()
            }else{
                this.$refs.carousel2.prev()
            }
        },

        touchstartFn3(e){
            this.startMove3 = e.changedTouches[0].clientX
        },
        touchendFn3(e){
            if(e.changedTouches[0].clientX - this.startMove3 > 50 ){
                this.changePage3(-3)
            }else if(e.changedTouches[0].clientX - this.startMove3 < -50){
                this.changePage3(3)
            }
        },
        changePage3(page){
            if(page > 0){
                this.$refs.carousel3.next()
            }else{
                this.$refs.carousel3.prev()
            }
        },

        touchstartFn4(e){
            this.startMove4 = e.changedTouches[0].clientX
        },
        touchendFn4(e){
            if(e.changedTouches[0].clientX - this.startMove4 > 50 ){
                this.changePage4(-4)
            }else if(e.changedTouches[0].clientX - this.startMove4 < -50){
                this.changePage4(4)
            }
        },
        changePage4(page){
            if(page > 0){
                this.$refs.carousel4.next()
            }else{
                this.$refs.carousel4.prev()
            }
        },

        touchstartFn5(e){
            this.startMove5 = e.changedTouches[0].clientX
        },
        touchendFn5(e){
            if(e.changedTouches[0].clientX - this.startMove5 > 50 ){
                this.changePage5(-5)
            }else if(e.changedTouches[0].clientX - this.startMove5 < -50){
                this.changePage5(5)
            }
        },
        changePage5(page){
            if(page > 0){
                this.$refs.carousel5.next()
            }else{
                this.$refs.carousel5.prev()
            }
        },
    }
}
</script>

<style lang="less" scoped>
.food{
    padding-bottom: 50px;
    background: #1C1C1C;
    .typeBox{
        margin: 48px auto 0;
        width: 331px;
        .info{
            margin-top: 16px;
            .left{
                position: relative;
                /deep/ .carousel{
                    .el-carousel__container{
                        height: 160px;
                        background: #ccc;
                        border-radius: 8px;
                        overflow: hidden;
                        .content{
                            height: 100%;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .el-carousel__indicator--horizontal{
                        padding: 16px 15px 0 0;
                    }
                }
            }
            .text{
                font-size: 14px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 24px;
                margin-top: 34px;
            }
            .color{
                color: #3DA6D5;
            }
        }
        .type{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            text-align: center;
        }
    }
    .typeBox1{
        margin-top: 24px;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>