<template>
    <div class="food">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="typeBox typeBox1">
            <div class="type">
                <span>{{$store.state.cn_en_list[158]}}</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 4" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodone' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    {{$store.state.cn_en_list[159]}}
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[160]}}</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodtwo' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    {{$store.state.cn_en_list[161]}}
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[162]}}</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodthree' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    {{$store.state.cn_en_list[163]}}
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[164]}}</span>
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/img/foodfourth' + item + '.png' " alt="" class="img">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="text">
                    {{$store.state.cn_en_list[165]}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from './../../utils/config'
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {}
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
        }else{
            this.configData = config.weiyuan.english
        }
    },

    
    mounted(){
        banner({'seat' : 9}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	}
}
</script>

<style lang="less" scoped>
.food{
    padding-bottom: 150px;
    background: #1C1C1C;
    .typeBox{
        margin-top: 64px;
        .info{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            .left{
                width: 587px;
                /deep/ .carousel{
                    .el-carousel__container{
                        height: 387px;
                        background: #ccc;
                        border-radius: 8px;
                        overflow: hidden;
                        .content{
                            height: 100%;
                            img{
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                    .el-carousel__indicator--horizontal{
                        padding: 16px 15px 0 0;
                    }
                }
            }
            .text{
                width: 521px;
                font-size: 20px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #fff;
                line-height: 40px;
                margin-left: 91px;
                padding-bottom: 24px;
            }
            .color{
                color: #3DA6D5;
            }
        }
        .type{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 54px;
                height: 44px;
                margin-left: 30px;
            }
        }
    }
    .typeBox1{
        margin-top: 120px;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>