<template>
    <div class="Mjoin">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>
        <div class="typeContent">
            <div class="typename">
                <div class="title">大会特色：常春藤名校分享会</div>
                <div class="carouselBox">
                    <div class="dots">
                        <div :class="Current1 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1" :key="index"></div>
                    </div>
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <img src="./../../assets/img/meeting1.png" alt="" class="typeImg">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                
                <div class="textBox">
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>你将有机会和美国常春藤高校的学长学姐当面交流学校生活的各种细节：如选课，宿舍，食堂，生活设施等等。</div>
                    </div>
                    <div class="text center">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>和在校生的交流将是你了解学校真实学习生活环境的最好机会，并且你可以提前熟悉申请的流程步骤，当面汲取申请季的经验，并为选校做好最充分的准备，未雨绸缪。</div>
                    </div>
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>在常春藤名校分享会中，通过与优秀的名校在校生和面试官们的交流，你将得到最详细、最内幕、最多样的优质信息源。</div>
                    </div>
                </div>
            </div>

            <div class="typename">
                <div class="title">大会特色：社交晚会</div>
                <div class="carouselBox">
                    <div class="dots">
                        <div :class="Current2 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1" :key="index"></div>
                    </div>
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="none">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <img src="./../../assets/img/meeting2.png" alt="" class="typeImg">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                
                <div class="textBox">
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>作为BetterWorldMUN的重要组成部分之一，社交晚会发挥着不可或缺的作用。</div>
                    </div>
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>大家通过参与和观看汇聚多元文化的才艺展演，可以与来自不同文化背景的代表们更快地交流熟络。</div>
                    </div>
                    <div class="text">
                        <div class="circleBox"><div class="circle"></div></div>
                        <div>社交晚会将进一步完善参会代表们的国际大会体验，拓展了交流渠道和社交能力，结识更多优秀的同龄人。</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="part">
            <div class="title">大会特色：校园大使</div>
            <div class="carouselBox">
                <div class="dots">
                    <div :class="Current3 == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in 1" :key="index"></div>
                </div>
                <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="none">
                    <el-carousel-item v-for="(item , index) in 1" :key="index">
                        <img src="./../../assets/img/meeting3.png" alt="" class="typeImg">
                        <div class="button"  @click="goAmbassador">我要报名</div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            
            <div class="listBox">
                <div class="list">
                    <div class="tip">
                        <div class="listtitle">BetterWorldMUN校园大使</div>
                        <div class="circle circleleft"></div>
                        <div class="circle circleright"></div>
                        <div class="lineBox lineBoxleft">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                        <div class="lineBox lineBoxright">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                    </div>

                    <div class="contentBox">
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">校园大使将在本校学生群体中搭建社群，负责日常答疑解惑，倾听并反馈同学们的声音。</div>
                        </div>
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">将成为各种活动专家，与我们一起做好线上、线下的BetterWorldMUN活动。</div>
                        </div>
                    </div>
                </div>
                <div class="list">
                    <div class="tip">
                        <div class="listtitle">期待这样的你</div>
                        <div class="circle circleleft"></div>
                        <div class="circle circleright"></div>
                        <div class="lineBox lineBoxleft">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                        <div class="lineBox lineBoxright">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                    </div>

                    <div class="contentBox">
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">各校模联社团或相关社团负责人，沟通能力强，乐意分享，有能力组织校园活动</div>
                        </div>
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">在BetterWorldMUN大会期间表现优异的大会代表</div>
                        </div>
                    </div>
                </div>
                <div class="list">
                    <div class="tip">
                        <div class="listtitle">获得专属福利</div>
                        <div class="circle circleleft"></div>
                        <div class="circle circleright"></div>
                        <div class="lineBox lineBoxleft">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                        <div class="lineBox lineBoxright">
                            <div class="line"></div>
                            <div class="fffcircle"></div>
                        </div>
                    </div>

                    <div class="contentBox">
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">BetterWorldMUN 校园大使证书</div>
                        </div>
                        <div class="text">
                            <div class="circle"></div>
                            <div class="span">BetterWorldMUN 精美周边以及惊喜礼品</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="schedule">
            <div class="title">大会日程</div>
            <div class="content">
                <div class="timeBox">
                    <div class="day">Day 1</div>
                    <div class="time">
                        <div class="timelist" v-for="(item , index) in step[0] " :key="index">
                            <div class="lineCut" v-if="index != 0"></div>
                            <div class="listBox">
                                <div class="clock">
                                    <img src="./../../assets//img//clock.png" alt="">
                                    <span>{{ item.time }}</span>
                                </div>
                                <div class="stepName">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="timeBox second">
                    <div class="day">Day 2</div>
                    <div class="time">
                        <div class="timelist" v-for="(item , index) in step[1] " :key="index">
                            <div class="lineCut" v-if="index != 0"></div>
                            <div class="listBox">
                                <div class="clock">
                                    <img src="./../../assets//img//clock.png" alt="">
                                    <span>{{ item.time }}</span>
                                </div>
                                <div class="stepName">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="timeBox">
                    <div class="day">Day 3</div>
                    <div class="time">
                        <div class="timelist" v-for="(item , index) in step[2] " :key="index">
                            <div class="lineCut" v-if="index != 0"></div>
                            <div class="listBox">
                                <div class="clock">
                                    <img src="./../../assets//img//clock.png" alt="">
                                    <span>{{ item.time }}</span>
                                </div>
                                <div class="stepName">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="timeBox second">
                    <div class="day">Day 4</div>
                    <div class="time">
                        <div class="timelist" v-for="(item , index) in step[3] " :key="index">
                            <div class="lineCut" v-if="index != 0"></div>
                            <div class="listBox">
                                <div class="clock">
                                    <img src="./../../assets//img//clock.png" alt="">
                                    <span>{{ item.time }}</span>
                                </div>
                                <div class="stepName">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="Awards">
            <div class="title">大会奖项</div>
            <div class="awardContent">
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award1.png" alt="">
                        <span>个人奖项</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">最佳代表</div>
                            </div>
                            <div class="desc">用于表彰会场实力最为突出的代表，每个委员会有且仅有一位代表/代表团获此奖项。</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">荣誉提名</div>
                            </div>
                            <div class="desc">用于表彰会场发挥优秀的代表，除去最佳代表和杰出代表，每个会场有一至四个代表/代表团获此奖项。</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">杰出代表</div>
                            </div>
                            <div class="desc">用于表彰会场发挥杰出的代表，每个委员会有且仅有一个或二个代表/代表团获此奖项。</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">最佳立场文件</div>
                            </div>
                            <div class="desc">用于表彰立场文件写作质量最佳的代表，每个会场有一至四个代表/代表团获此奖项。</div>
                        </div>
                    </div>
                </div>
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award2.png" alt="">
                        <span>团队奖项</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">最佳团队</div>
                            </div>
                            <div class="desc">用于表彰大会实力最为突出的学校代表团，整个大会有且仅有一个学校代表团获此奖项。</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">优秀团队</div>
                            </div>
                            <div class="desc">用于表彰大会表现优秀的代表团，整个大会一至四个学校代表团获此奖项。</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">杰出团队</div>
                            </div>
                            <div class="desc">用于表彰大会表现杰出的代表团，整个大会一或二个学校代表团获此奖项。</div>
                        </div>
                    </div>
                </div>
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award3.png" alt="">
                        <span>教师奖项</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">优秀指导教师</div>
                            </div>
                            <div class="desc">用于表彰优秀的代表团指导教师</div>
                        </div>
                    </div>
                </div>
                <div class="award">
                    <div class="name">
                        <img src="./../../assets/img/award4.png" alt="">
                        <span>特别奖项</span>
                    </div>
                    <div class="tips">
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">大会全部获奖代表</div>
                            </div>
                            <div class="desc">获奖代表将自动进入BetterWorld全球青少年模拟联合国大会名人堂候选名单。我们会定期公布最新BetterWorldMUN名人堂排行榜。</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">获得最佳代表和杰出代表的参会者</div>
                            </div>
                            <div class="desc">获得最佳代表和杰出代表的参会者</div>
                        </div>
                        <div class="tipBox">
                            <div class="circleBox">
                                <div class="circle"></div>
                                <div class="tipName">获得最佳代表的参会者</div>
                            </div>
                            <div class="desc">获得与常春藤高校面试官一对一交流的机会。</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info">
                <div class="text">评选规则备注：</div>
                <div class="text">1）个人奖项评选：主席团将依据代表的实际表现进行奖项颁发，上述个人奖项数量仅为参考，主席团会结合代表总数增加或减少获奖数量。</div>
                <div class="text">2）团体奖项评选：将个人奖项按照分数核算，累计分数最高的学校获得最佳团队奖，以此类推</div>
                <div class="text">3）分数核算规则：最佳代表每席四分、杰出代表每席三分、荣誉提名每席二分、最佳立场文件每席一分。</div>
            </div>
        </div>


        <div class="joinContent">
            <div class="title">其他大会信息</div>
            <div class="type">
                <div class="typeBox" @click="nextPage(1)">
                    <img src="./../../assets/img/Mjoin1.png" alt="">
                    <div class="titleSpan">{{ configData.root1 }}</div>
                </div>
                <div class="typeBox" @click="nextPage(2)">
                    <img src="./../../assets/img/Mjoin2.png" alt="">
                    <div class="titleSpan">{{ configData.root2 }}</div>
                </div>
                <div class="typeBox" @click="nextPage(3)">
                    <img src="./../../assets/img/Mjoin3.png" alt="">
                    <div class="titleSpan">{{ configData.root3 }}</div>
                </div>
                <div class="typeBox" @click="nextPage(4)">
                    <img src="./../../assets/img/Mjoin4.png" alt="">
                    <div class="titleSpan">{{ configData.root4 }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {banner} from './../../api/api'
import {config} from './../../utils/config'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            load : false,
            height : '252',
            configData : {},
            step: [
                [{
                        time: '13:00-14:30',
                        name: '代表签到',
                    },
                    {
                        time: '15:30-16:00',
                        name: '委员会报到',
                    },
                    {
                        time: '16:00-17:30',
                        name: '大会开幕式',
                    },
                    {
                        time: '17:30-19:00',
                        name: '晚餐',
                    },
                    {
                        time: '19:00-20:00',
                        name: '破冰环节',
                    },
                    {
                        time: '20:00-21:30',
                        name: '分组会议1',
                    },
                    {
                        time: '22:30',
                        name: '宵禁',
                    },
                ],
                [{
                        time: '9:00-12:00',
                        name: '分组会议2',
                    },
                    {
                        time: '12:00-13:00',
                        name: '午餐',
                    },
                    {
                        time: '14:30-17:30',
                        name: '分组会议3',
                    },
                    {
                        time: '17:30-19:00',
                        name: '晚餐',
                    },
                    {
                        time: '19:00-21:30',
                        name: '分组会议4',
                    },
                    {
                        time: '22:30',
                        name: '宵禁',
                    },
                ],
                [{
                        time: '9:00-12:00',
                        name: '分组会议5',
                    },
                    {
                        time: '12:00-13:00',
                        name: '午餐',
                    },
                    {
                        time: '14:30-17:30',
                        name: '分组会议6',
                    },
                    {
                        time: '17:30-18:30',
                        name: '晚餐',
                    },
                    {
                        time: '18:30-19:30',
                        name: '藤校申请讲座',
                    },
                    {
                        time: '20:00-22:30',
                        name: '社交晚会',
                    },
                    {
                        time: '23:00',
                        name: '宵禁',
                    },
                ],
                [{
                        time: '08:30-10:00',
                        name: '分组会议7',
                    },
                    {
                        time: '10:30-12:00',
                        name: '闭幕式',
                    },
                    {
                        time: '12:00',
                        name: '代表返程',
                    },
                ]
            ],
            Current1 : 0,
            Current2 : 0,
            Current3 : 0,
        }
    },
    mounted(){
        if(this.$store.state.language.type == 1){
            this.configData = config.join.chinese
        }else{
            this.configData = config.join.english
        }
        banner({'seat' : 2,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        imgLoad(){
            this.height = document.querySelector('.typeBox img').height
        },
        //校园大使报名
        goAmbassador(){
            this.$router.push('/MAmbassador')
        },
        nextPage(type){
			if(type == 1){
				this.$router.push('/weiyuan')
			}else if(type == 2){
				this.$router.push('/preparation')
			}else if(type == 3){
				this.$router.push('/food')
			}else if(type == 4){
				// this.$router.push('/city')
                this.$message({ message: this.$store.state.cn_en_list[156], type: 'success' })
			}
		}
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.join.chinese
			}else{
				this.configData = config.join.english
			}
		}
	}
}
</script>
<style lang="less" scoped>
.Mjoin{
    background: #1C1C1C;
    text-align: center;
    .carouselBox{
        position: relative;
    }
    .Awards{
        width: 331px;
        margin: 48px auto 0;
        .info{
            font-size: 12px;
            font-family: AlibabaPuHuiTi_2_55_Regular;
            color: #AAAAAA;
            line-height: 27px;
            text-align: left;
            .text{
                margin-top: 24px;
            }
            .text:nth-of-type(1){
                margin-top: 12px;
            }
            .text:nth-of-type(2){
                margin-top: 0;
            }
        }
        .title{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            text-align: center;
        }
        .awardContent{
            .award{
                width: 100%;
                background: linear-gradient(180deg, rgba(54,54,54,0.65) 0%, rgba(53,53,53,0) 40%, rgba(52,52,52,0) 68%, rgba(51,51,51,0.65) 100%);
                border-radius: 8px;
                border: 1px solid #fff;
                margin-top: 24px;
                display: flex;
                align-items: center;
                flex-direction: column;
                padding-top: 34px;
                padding-bottom: 40px;
                .name{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 90px;
                        height: 90px;
                    }
                    span{
                        font-size: 20px;
                        font-family: YouSheBiaoTiHei;
                        color: #FFFFFF;
                        line-height: 26px;
                        margin-top: 15px;
                        white-space: nowrap;
                    }
                }
                .tips{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .tipBox{
                        margin-left: 16px;
                        margin-top: 20px;
                        .circleBox{
                            display: flex;
                            align-items: center;
                            .circle{
                                width: 10px;
                                height: 10px;
                                background: #FFFFFF;
                                border-radius: 50%;
                            }
                            .tipName{
                                font-size: 16px;
                                font-family: AlibabaPuHuiTi_2_55_Regular;
                                color: #3DA6D5;
                                line-height: 22px;
                                margin-left: 12px;
                            }
                        }
                        .desc{
                            font-size: 14px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #FFFFFF;
                            line-height: 24px;
                            margin-top: 12px;
                            width: 281px;
                            margin-left: 22px;
                            text-align: left;
                        }
                    }
                    .tipBox:nth-of-type(1){
                        margin-top: 13px;
                    }
                }
            }
            .award:nth-of-type(1){
                margin-top: 16px;
            }
        }
    }
    .schedule{
        width: 331px;
        margin: 48px auto 0;
        overflow: hidden;
        .content{
            padding-left: 10px;
            margin-top: 24px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .timeBox{
                margin-top: 40px;
                .time{
                    margin-top: 17px;
                    .timelist{
                        .lineCut{
                            width: 2px;
                            height: 13px;
                            opacity: 0.6;
                            background: #fff;
                            margin: 3px 9px;
                        }
                        .listBox{
                            display: flex;
                            align-items: center;
                            .stepName{
                                font-size: 14px;
                                font-family: AlibabaPuHuiTi_2_55_Regular;
                                color: #FFFFFF;
                                line-height: 20px;
                                white-space: nowrap;
                            }
                        }
                        .clock{
                            font-size: 14px;
                            font-family: YouSheBiaoTiHei;
                            color: #FFFFFF;
                            line-height: 18px;
                            display: flex;
                            align-items: center;
                            width: 185px;
                            img{
                                width: 20px;
                                height: 20px;
                                margin-right: 12px;
                            }
                        }
                    }
                }
                .day{
                    font-size: 18px;
                    font-family: YouSheBiaoTiHei;
                    color: #3DA6D5;
                    line-height: 23px;
                    text-align: left;
                }
            }
            .timeBox:nth-of-type(1){
                margin-top: 24px;
            }
        }
        .title{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
            text-align: center;
        }
    }
    .part{
        width: 331px;
        margin: 48px auto 0;
        .listBox{
            
            .list{
                width: 100%;
                height: 208px;
                background: #333333;
                border-radius: 8px;
                position: relative;
                margin-top: 86px;
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 32px;
                    float: left;
                    width: 100%;
                    .text{
                        display: flex;
                        .span{
                            font-size: 14px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #D1D1D1;
                            line-height: 24px;
                            width: 271px;
                            text-align: left;
                        }
                        .circle{
                            width: 8px;
                            height: 8px;
                            background: #FFFFFF;
                            border-radius: 50%;
                            margin-right: 8px;
                            margin-top: 8px;
                        }
                    }
                    .text:nth-of-type(2){
                        margin-top: 24px;
                    }
                }
                .tip{
                    width: 220px;
                    height: 36px;
                    background: #FFFFFF;
                    border-radius: 21px;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -48px;
                    .listtitle{
                        font-size: 18px;
                        font-family: YouSheBiaoTiHei;
                        color: #1C1C1C;
                        line-height: 36px;
                        text-align: center;
                    }
                    .circle{
                        width: 12px;
                        height: 12px;
                        background: #1C1C1C;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .circleleft{
                        left: 14px;
                    }
                    .circleright{
                        right: 14px;
                    }
                    .lineBox{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: absolute;
                        top: 29px;
                        .line{
                            width: 2px;
                            height: 22px;
                            background: #fff;
                        }
                        .fffcircle{
                            width: 8px;
                            height: 8px;
                            background: #FFFFFF;
                            border-radius: 50%;
                            margin-top: -3px;
                        }
                    }
                    .lineBoxleft{
                        left: 23px;
                    }
                    .lineBoxright{
                        right: 23px;
                    }
                }
            }
            .list:nth-of-type(3){
                height: 136px;
                margin-top: 64px;
                .contentBox{
                    .text{
                        margin-top: 24px;
                    }
                    .text:nth-of-type(1){
                        margin-top: 0;
                    }
                }
            }
            .list:nth-of-type(2){
                margin-top: 64px;
                height: 184px;
            }
            .list:nth-of-type(1){
                .tip{
                    width: 314px;
                }
            }
        }
        .title{
            font-size: 22px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 29px;
            text-align: center;
        }
        .carousel{
            margin-top: 16px;
            height: 160px;
            /deep/ .el-carousel__container{
                height: 100%;
            }
            .typeImg{
                width: 100%;
                height: 100%;
            }
            .button{
                width: 113px;
                height: 48px;
                background: rgba(0,0,0,0.2);
                border-radius: 8px;
                border: 1px solid #FFFFFF;
                font-size: 24px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 46px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50% , -50%);
                text-align: center;
            }
        }
    }
    .typeContent{
        width: 331px;
        margin: 0 auto;
        .typename{
            margin-top: 48px;
            .title{
                font-size: 22px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 29px;
            }
            .carousel{
                width: 100%;
                height: 160px;
                margin-top: 16px;
                /deep/ .el-carousel__container{
                    height: 100%;
                    .typeImg{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .textBox{
                .text{
                    font-size: 14px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #D1D1D1;
                    line-height: 24px;
                    display: flex;
                    text-align: left;
                    margin-top: 20px;
                    .circleBox{
                        margin-right: 8px;
                        margin-top: 8px;
                        .circle{
                            width: 9px;
                            height: 9px;
                            background: #FFFFFF;
                            border-radius: 50%;
                        }
                    }
                }
                .text:nth-of-type(1){
                    margin-top: 30px;
                }
            }
        }
    }
    .joinContent{
        margin-top: 48px;
        .title{
            font-size: 24px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 31px;
        }
        .type{
            margin: 16px auto 0;
            .typeBox{
                width: 331px;
                height: 232px;
                position: relative;
                overflow: hidden;
                margin: 24px auto;
                img{
                    width: 100%;
                    height: 100%;
                }
                .titleSpan{
                    padding: 0 14px;
                    height: 50px;
                    background: rgba(56,56,56,0.2);
                    border: 1px solid #FFFFFF;
                    font-size: 32px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 48px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                    border-radius: 8px;
                }
            }
            .typeBox:active{
                img{
                    transform: scale(1.1);
                    transition: 0.2s;
                }
                .model{
                    color: #fff;
                }
            }
        }
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
        }
    }
}
</style>