<template>
    <div class="food">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="typeBox typeBox1">
            <div class="type">
                <span>{{$store.state.cn_en_list[166]}}</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiOne' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        <span>{{$store.state.cn_en_list[167]}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[168]}}</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiTwo' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        {{$store.state.cn_en_list[169]}}
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[170]}}</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiThree' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        {{$store.state.cn_en_list[171]}}
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[172]}}</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuiFour' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        {{$store.state.cn_en_list[173]}}
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[174]}}</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuifive' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        {{$store.state.cn_en_list[175]}}
                    </div>
                </div>
            </div>
        </div>

        <div class="typeBox">
            <div class="type">
                <span>{{$store.state.cn_en_list[176]}}</span>
                
            </div>
            <div class="info">
                <div class="left">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="content">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/canhuisix' + item + '.png' " alt="" class="img">
                                <div class="button" @click="lookTemplate">{{$store.state.cn_en_list[157]}}</div>
                                <div class="model"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <div class="text">
                        {{$store.state.cn_en_list[177]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from '../../utils/config'
import {banner} from '../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {}
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
        }else{
            this.configData = config.weiyuan.english
        }
    },
    mounted(){
        
        banner({'seat' : 8}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    methods : {
        lookTemplate(){
            this.$message({ message: this.$store.state.cn_en_list[199], type: 'success' })
        }
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	}
}
</script>

<style lang="less" scoped>
.food{
    padding-bottom: 150px;
    background: #1C1C1C;
    .typeBox{
        margin-top: 64px;
        .info{
            display: flex;
            // align-items: center;
            justify-content: center;
            margin-top: 40px;
            .left{
                width: 587px;
                /deep/ .carousel{
                    height: 100%;
                    .el-carousel__container{
                        height: 284px;
                        border-radius: 8px;
                        overflow: hidden;
                        cursor: pointer;
                        .content{
                            height: 100%;
                        }
                        img{
                            height: 100%;
                        }
                        .button{
                            width: 170px;
                            height: 64px;
                            background: rgba(0,0,0,0.2);
                            border-radius: 8px;
                            border: 1px solid #FFFFFF;
                            font-size: 30px;
                            font-family: YouSheBiaoTiHei;
                            color: #FFFFFF;
                            line-height: 62px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50% , -50%);
                            text-align: center;
                            z-index: 1;
                        }
                        .model{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                    .el-carousel__container:hover{
                        .button{
                            background: #3DA6D5;
                            border-color: #3DA6D5;
                        }
                        .model{
                            background: rgba(0, 0, 0, 0.39);
                        }
                    }
                    .el-carousel__indicator--horizontal{
                        padding: 16px 15px 0 0;
                    }
                }
            }
            .right{
                width: 586px;
                margin-left: 27px;
                .text{
                    margin-top: 46px;
                    font-size: 20px;
                    font-family: AlibabaPuHuiTi_2_55_Regular;
                    color: #FFFFFF;
                    line-height: 32px;
                }
            }
        }
        .type{
            font-size: 34px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 44px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 54px;
                height: 44px;
                margin-left: 30px;
            }
        }
    }
    .typeBox1{
        margin-top: 80px;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
</style>