<template>
    <div class="Mvideo">
        <div class="MvideoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ data.title }}</div>
            <div class="title2">{{ data.describe }}</div>
            <video :src="data.url" ref="video" autoplay loop muted webkit-playsinline="true" playsinline="true" ></video>
        </div>
        <div class="preheat">
            <div class="title">嘉宾欢迎视频</div>
            <div class="videoContent"  @touchstart="touchstartFn" @touchend="touchendFn">
                <div class="dots">
                    <div :class="Current == index ? 'activeDots dot' : 'dot' " v-for="(item , index) in videoHot" :key="index"></div>
                </div>
                <el-carousel  ref="carousel" @change="change"  class="carousel" :interval="5000" arrow="never" indicator-position="none">
                    <el-carousel-item v-for="(item , index) in videoHot" :key="index">
                        <div class="Box" @click="playVideo(item)">
                            <video :src="item.url" :poster="item.cover" class="radius"></video>
                            <div class="name radius">{{ item.title }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="Speaker">
            <div class="title">BWMUN大会视频</div>
            <div class="contentBox">
                <div class="SpeakerBox" v-for="(item , index) in videoList" :key="index" @click="playVideo(item)">
                    <img :src="item.cover" alt="" class="bg radius">
                    <div class="titleContent radius">{{ item.title }}</div>
                    <!-- <div class="model">
                        <div class="say">{{item.describe}}</div>
                        <div class="name">{{item.nickname}}</div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="videoModel" v-if="videoFlag" @click.stop.capture="closeVideo">
            <video :src="videoInfo.url" controls autoplay controlslist="nodownload" disablePictureInPicture></video>
        </div>

    </div>
</template>


<script>
import {banner , video} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                video_url : '',
            },
            form : {
                name : ''
            },
            load : false,
            videoList : [],
            videoHot : [],
            startMove : 0,
            Current : 0,
            videoInfo : {},
            videoFlag : false
        }
    },
    mounted(){
        banner({'seat' : 3,'device': 2}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                setTimeout(() => {
                    this.load = true
                } , 800)
            } else {
                this.$message.error(res.data.msg)
            }
        })
        this.getVideoList(2)
        this.getVideoList(3)
    },
    methods : {
        closeVideo(e){
            if(e.target.className == 'videoModel'){
                this.videoFlag = false
            }
        },
        playVideo(item){
            if(item.url){
                this.videoInfo = item
                this.videoFlag = true
            }else{
                this.$message({ message: this.$store.state.cn_en_list[200], type: 'success' })
            }
        },
        change(Current){
            this.Current = Current
        },
        showVideo(){
            this.$message({ message: this.$store.state.cn_en_list[200], type: 'success' })
        },
        //手机滑动切换
        touchstartFn(e){
            this.startMove = e.changedTouches[0].clientX
        },
        touchendFn(e){
            if(e.changedTouches[0].clientX - this.startMove > 50 ){
                this.changePage(-1)
            }else if(e.changedTouches[0].clientX - this.startMove < -50){
                this.changePage(1)
            }
        },
        changePage(page){
            if(page > 0){
                this.$refs.carousel.next()
            }else{
                this.$refs.carousel.prev()
            }
        },
        getVideoList(type){
            video({
                'type': type
            }).then(res => {
                if(type == 2){
                    this.videoHot = res.data.data
                }else if(type == 3){
                    this.videoList = res.data.data
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.Mvideo{
    background: #1C1C1C;
    text-align: center;
    padding-bottom: 50px;
    .videoModel{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background: rgba(28, 28, 28, 0.5);
        video{
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;
        }
    }
    .Speaker{
        margin-top: 46px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
        }
        .SpeakerBox{
            width: 331px;
            height: 187px;
            margin-top: 16px;
            position: relative;
            .model{
                width: 100%;
                height: 48px;
                background: rgba(0,0,0,0.7);
                font-size: 10px;
                font-family: AlibabaPuHuiTi_2_85_Bold;
                color: #EB0028;
                line-height: 26px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 0;
                .name{
                    margin-top: -6px;
                }
            }
            img{
                width: 100%;
                height: 100%;
            }
            .titleContent{
                height: 32px;
                background: rgba(56,56,56,0.2);
                border-radius: 8px;
                border: 1px solid #FFFFFF;
                padding: 0 12px;
                font-size: 20px;
                font-family: YouSheBiaoTiHei;
                color: #FFFFFF;
                line-height: 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50% , -50%);
                white-space: nowrap;
            }
        }
    }
    .preheat{
        margin-top: 24px;
        .videoContent{
            width: 331px;
            height: 187px;
            margin: 16px auto 0;
            position: relative;
            /deep/ .el-carousel__container{
                height: 187px;
                width: 100%;
            }
            .Box{
                width: 100%;
                height: 100%;
                position: relative;
                .cover{
                    width: 100%;
                    height: 100%;
                    background: #9F9F9F;
                }
                video{
                    width: 100%;
                    height: 100%;
                }
                .name{
                    height: 32px;
                    background: rgba(56,56,56,0.2);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50% , -50%);
                    font-size: 20px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 30px;
                    padding: 0 12px;
                }
            }
        }
        .title{
            font-size: 26px;
            font-family: YouSheBiaoTiHei;
            color: #FFFFFF;
            line-height: 34px;
        }
    }
}
</style>