<template>
    <div class="weiyuan">
        <div class="videoBox">
            <div class="model" :class="load ? 'blackModel' : '' "></div>
            <div class="title">{{ $store.state.language.type == 1 ? data.title : data.title_en }}</div>
            <div class="title2">{{ $store.state.language.type == 1 ? data.describe : data.describe_en }}</div>
            <video :src="data.url" autoplay loop muted></video>
        </div>
        <div class="content">
            <div class="Box firstBox">
                <div class="wrapper">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanOne' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">{{$store.state.cn_en_list[178]}}</div>
                    <div class="text firstText">{{$store.state.cn_en_list[179]}}</div>
                    <div class="text">{{$store.state.cn_en_list[180]}}</div>
                    <div class="desc">
                        {{$store.state.cn_en_list[181]}}
                    </div>
                </div>
            </div>
            <div class="Box">
                <div class="wrapper">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanTwo' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">United Nations Security Council</div>
                    <div class="text firstText">{{$store.state.cn_en_list[183]}}</div>
                    <div class="text">{{$store.state.cn_en_list[184]}}</div>
                    <div class="desc">
                        {{$store.state.cn_en_list[185]}}
                    </div>
                    
                </div>
            </div>
            <div class="Box">
                <div class="wrapper">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanThree' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">{{$store.state.cn_en_list[186]}}</div>
                    <div class="text firstText">{{$store.state.cn_en_list[187]}}</div>
                    <div class="text">{{$store.state.cn_en_list[180]}}</div>
                    <div class="desc">
                        {{$store.state.cn_en_list[188]}}
                    </div>
                    
                </div>
            </div>
            <div class="Box firstBox">
                <div class="wrapper">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanFour' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">United Nations Security Council</div>
                    <div class="text firstText">{{$store.state.cn_en_list[189]}}</div>
                    <div class="text">{{$store.state.cn_en_list[184]}}</div>
                    <div class="desc">
                        {{$store.state.cn_en_list[190]}}
                    </div>
                    
                </div>
            </div>
            <div class="Box">
                <div class="wrapper">
                    <el-carousel   ref="carousel"  class="carousel" arrow="never" :interval="10000"  indicator-position="outside">
                        <el-carousel-item v-for="(item , index) in 1" :key="index">
                            <div class="wrappercontent">
                                <img :src=" 'https://www.betterworldmun.com/uploads/wxapp/img/weiyuanFive' + item + '.png' " alt="" class="img">
                                
                                
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="info">
                    <div class="infoTitle">{{$store.state.cn_en_list[191]}}</div>
                    <div class="text firstText">{{$store.state.cn_en_list[192]}}</div>
                    <div class="text">{{$store.state.cn_en_list[180]}}</div>
                    <div class="desc">
                        {{$store.state.cn_en_list[193]}}
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {config} from './../../utils/config'
import {banner} from './../../api/api'
export default {
    data(){
        return {
            data : {
                title : '',
                describe : '',
                url : '',
            },
            load : false,
            configData : {}
        }
    },
    beforeMount(){
        if(this.$store.state.language.type == 1){
            this.configData = config.weiyuan.chinese
            this.configData = config.weiyuan.english
        }else{
            this.configData = config.weiyuan.english
        }
    },
    mounted(){
        
        banner({'seat' : 7}).then(res => {
            if (res.data.code === '10000') {
                this.data = res.data.data[0]
                if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
                    setTimeout(() => {
                        this.load = true
                    } , 800)
                }else{
                    this.load = true
                }
            } else {
                this.$message.error(res.data.msg)
            }
        })
    },
    watch : {
		"$store.state.language" : function (){
			if(this.$store.state.language.type == 1){
				this.configData = config.weiyuan.chinese
			}else{
				this.configData = config.weiyuan.english
			}
		}
	}
}
</script>

<style lang="less" scoped>
.content{
    width: 1200px;
    margin: 0 auto;
    .Box{
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        .wrapper{
            width: 587px;
            /deep/ .el-carousel__container{
                height: 284px;
            }
            .wrappercontent{
                height: 100%;
                cursor: pointer;
                .img{
                    height: 100%;
                    width: 100%;
                }
                .jianjie{
                    width: 170px;
                    height: 64px;
                    background: rgba(0,0,0,0.2);
                    border-radius: 8px;
                    border: 1px solid #FFFFFF;
                    font-size: 30px;
                    font-family: YouSheBiaoTiHei;
                    color: #FFFFFF;
                    line-height: 62px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50% , -50%);
                    text-align: center;
                    z-index: 1;
                }
                .model{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
        .info{
            width: 586px;
            .infoTitle{
                font-size: 28px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 32px;
            }
            .text{
                font-size: 18px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #FFFFFF;
                line-height: 32px;
                margin-top: 12px;
            }
            .firstText{
                margin-top: 24px;
            }
            .desc{
                margin-top: 24px;
                font-size: 16px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #AAAAAA;
                line-height: 32px;
            }
            .getMore{
                font-size: 16px;
                font-family: AlibabaPuHuiTi_2_55_Regular;
                color: #3DA6D5;
                line-height: 32px;
            }
        }
    }
    // .firstBox{
    //     margin-top: 40px;
    // }
    .title{
        font-size: 34px;
        font-family: YouSheBiaoTiHei;
        color: #FFFFFF;
        line-height: 44px;
        margin-top: 64px;
        text-align: center;
    }
}
.videoBox{
    video{
        top: unset;
        bottom: 0;
    }
}
.weiyuan{
    padding-bottom: 150px;
    background: #1C1C1C;
}
</style>